import React from "react";
import { Component } from "react";
import "../TodoList/ToDo.css";

class ToDoList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      itemName: "",
      itemNameList: [],
    };
  }
  TextChange = (e) => {
    this.setState({ itemName: e.target.value });
  };

  editIndex = null;

  OnSubmit = () => {
    if (!this.state.itemName == "") {
      if (this.editIndex === null) {
        this.state.itemNameList.push(this.state.itemName);
      } else {
        this.state.itemNameList.splice(this.editIndex, 1, this.state.itemName);
      }
      this.setState({
        itemNameList: this.state.itemNameList,
        itemName: "",
      });
    }
    this.editIndex = null;
  };

  delete = (item, e) => {
    const removed = this.state.itemNameList.filter((a) => {
      return item !== a;
    });

    this.setState({ itemNameList: removed });
  };

  Edit = (item, i) => {
    this.setState({ itemName: item });
    this.editIndex = i;
  };

  render() {
    return (
      <div className="Container">
        <h1>ToDoList</h1>
        <div className="card">
          <h2> Add Item </h2>
          <div className="item">
            <input
              type="text"
              value={this.state.itemName}
              onChange={this.TextChange}
            />
            <button type="submit" onClick={this.OnSubmit}>
              +
            </button>
          </div>

          {this.state.itemNameList.map((item, index) => (
            <div className="flex-item">
              <div className="flex-item1">
                <h4>{item}</h4>
              </div>
              <div className="btn-List">
                <div className="flex-item2">
                  <button
                    className="btn"
                    onClick={() => this.delete(item, index)}
                  >
                    x
                  </button>
                </div>
                <div className="flex-item3">
                  <button
                    className="btn"
                    onClick={() => this.Edit(item, index)}
                  >
                    Edit
                  </button>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  }
}
export default ToDoList;
